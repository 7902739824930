import {
  InsuranceCardMolecule as InsuranceCardMoleculeComponents,
  SpinnerCenteredAtom,
  TrackerStepEnum,
} from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { paths } from "../../../constants";
import { useInsurance, useInsuranceContext, useProducts } from "../../../hooks";
import {
  IInsuranceQuoteData,
  IPasswordlessParams,
  ITrackerInformationResponse,
  InsuranceCompanyEnum,
  OriginEnum,
  ProposalStepEnum,
} from "../../../models";
import {
  LogoAzulSeguros,
  LogoIturan,
  LogoPortoSeguro,
  LogoYelumSeguradora,
  dtIcon,
  shieldIcon
} from "../../assets";
import { BodyIconDiv, CardBodyIcons } from "./insurance-card.molecule.style";

interface IProps {
  data: IInsuranceQuoteData;
  viewCreditOnClick?: () => void;
  viewInsuranceOnClick?: () => void;
}

export const InsuranceCardMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { typeJourney } = useParams<IPasswordlessParams>();

  const { getCustomerById } = useInsurance();
  const { getTrackerInformation } = useProducts();
  const { setOrigin } = useInsuranceContext();

  const getStatusColor = (status: ProposalStepEnum) => {
    switch (status) {
      case ProposalStepEnum.Refused:
      case ProposalStepEnum.Cancelled:
        return "#C4390A";
      case ProposalStepEnum.Approved:
        return "#4AA434";
      case ProposalStepEnum.PolicyIssued:
        return "#0585C8";
      case ProposalStepEnum.Waiting:
      default:
        return "#EEA200";
    }
  };

  const getStatusIconName = (status: ProposalStepEnum): string => {
    switch (status) {
      case ProposalStepEnum.Refused:
        return "danger-color";
      case ProposalStepEnum.Approved:
        return "approved-color";
      case ProposalStepEnum.Waiting:
      default:
        return "warning-color";
    }
  };

  const [fillColor, setFillColor] = useState<string>("");
  const [statusIconName, setStatusIconName] = useState<string>("");

  useEffect(() => {
    setFillColor(getStatusColor(props.data.step));
    setStatusIconName(getStatusIconName(props.data.step));
  }, [props.data.step]);

  const [trackerStatus, setTrackerStatus] =
    useState<ITrackerInformationResponse>();

  useEffect(() => {
    if (props.data && props.data.company === InsuranceCompanyEnum.Ituran) {
      getTrackerInformation(props.data.trackerId).then((t) =>
        setTrackerStatus(t)
      );
    }
    // eslint-disable-next-line
  }, [props.data]);

  const getInsuranceCompanyLogo = (InsuranceCompany: InsuranceCompanyEnum) => {
    switch (InsuranceCompany) {
      case InsuranceCompanyEnum.Azul:
        return (
          <Image
            width={51}
            height={28.2}
            src={LogoAzulSeguros}
            alt="Logo Azul Seguros"
          />
        );

      case InsuranceCompanyEnum.Liberty:
        return (
          <Image
            width={100}
            height={30}
            src={LogoYelumSeguradora}
            alt="Logo Yelum Seguradora"
          />
        );

      case InsuranceCompanyEnum.PortoSeguro:
        return (
          <Image
            width={63}
            height={17.96}
            src={LogoPortoSeguro}
            alt="Logo Porto Seguro"
          />
        );
      case InsuranceCompanyEnum.Ituran:
        return (
          <Image width={70} height={31} src={LogoIturan} alt="Logo Ituran" />
        );
      default:
        return <></>;
    }
  };

  const [loadingCustomerData, setLoadingCustomerData] = useState(false);

  const getBodyIcons = () => {
    const quoteId = props.data.customerId?.toString() ?? "";
    const continueToQuotes = [
      ProposalStepEnum.Submitted,
      ProposalStepEnum.Quotation,
    ].includes(props.data.step);
    const continueToFinalData = props.data.step === ProposalStepEnum.Created;

    const viewInsurance = [
      ProposalStepEnum.Approved,
      ProposalStepEnum.Waiting,
      ProposalStepEnum.PolicyIssued,
    ].includes(props.data.step);

    const isDt = props.data.origin === OriginEnum.Dt;
    const continueProposal = continueToQuotes || continueToFinalData;

    const getTrackerUrlFromStep = (
      id: string | number,
      step: TrackerStepEnum
    ) => {
      switch (step) {
        case TrackerStepEnum.Order:
        case TrackerStepEnum.Quote:
        case TrackerStepEnum.Driver:
          return `tracker/quotes/${id}`;
        case TrackerStepEnum.Vehicle:
          return `tracker/driver/${id}`;
        case TrackerStepEnum.CreatedFromInsurance:
          return `tracker/vehicle/${id}`;
        case TrackerStepEnum.Canceled:
        case TrackerStepEnum.Finished:
        case TrackerStepEnum.Scheduled:
        case TrackerStepEnum.Scheduling:
        default:
          return "tracker/my-quotes";
      }
    };

    const redirectTrackerPath = (
      id: string | number,
      step: TrackerStepEnum,
      passwordless?: string
    ) => {
      window.location.href =
        process.env.REACT_APP_FRONT_PRODUCTS_URL +
        "/#/" +
        getTrackerUrlFromStep(id, step) +
        `/${passwordless ?? ""}`;
    };

    return (
      <CardBodyIcons className={"mt-2 mt-md-0"}>
        {(continueProposal || viewInsurance) && (
          <BodyIconDiv
            disabled={!!props.data.trackerId && trackerStatus === undefined}
            isLoading={loadingCustomerData}
            onClick={
              props.data.trackerId && trackerStatus === undefined
                ? undefined
                : props.data.trackerId
                ? () =>
                    redirectTrackerPath(
                      props.data.trackerId,
                      trackerStatus?.step ?? 0,
                      typeJourney
                    )
                : continueToQuotes
                ? () => {
                    setOrigin(props.data.origin);
                    history.push(paths.quotes(quoteId, typeJourney));
                  }
                : continueToFinalData
                ? () => {
                    setLoadingCustomerData(true);
                    setOrigin(props.data.origin);
                    getCustomerById(props.data.customerId)
                      .then(({ customer }) =>
                        history.push(
                          paths.finalData(
                            customer.publicPersonId,
                            customer.encodeLenderCode,
                            typeJourney
                          )
                        )
                      )
                      .catch(() =>
                        console.log(
                          "erro ao redirecionar para a página de dados finais"
                        )
                      )
                      .finally(() => setLoadingCustomerData(false));
                  }
                : props.viewInsuranceOnClick
            }
          >
            {props.data.trackerId && trackerStatus === undefined ? (
              <SpinnerCenteredAtom />
            ) : (
              <Image src={shieldIcon} alt="shield-icon" />
            )}
            <div>{continueProposal ? t("Continue") : t("ViewInsurance")}</div>
          </BodyIconDiv>
        )}
        {isDt && (
          <BodyIconDiv onClick={props.viewCreditOnClick}>
            <Image src={dtIcon} alt="dt-icon" />
            <div>{t("ViewCredit")}</div>
          </BodyIconDiv>
        )}
      </CardBodyIcons>
    );
  };

  return (
    <InsuranceCardMoleculeComponents
      fillColor={fillColor}
      statusIconName={statusIconName}
      header={{
        companyLogoImage: getInsuranceCompanyLogo(props.data.company),
        quoteId: props.data.quoteId,
        statusText: !!props.data.trackerId
          ? t("TrackerQuote")
          : t(ProposalStepEnum[props.data.step]),
      }}
      body={{
        title: props.data.name,
        subtitle: props.data.vehicle?.plate?.toUpperCase(),
        date: props.data.stepsLog?.length
          ? props.data.stepsLog[0].createdAt
          : "",
        icons: getBodyIcons(),
      }}
      details={
        props.data.stepsLog && {
          showMoreText: t("ShowMore"),
          showLessText: t("ShowLess"),
          title: undefined,
          description: undefined,
          stepsLog: props.data.stepsLog.map((m) => {
            return {
              text: t(ProposalStepEnum[m.step]),
              dateIsoString: m.createdAt,
            };
          }),
        }
      }
    />
  );
};
