import { ButtonTertiary } from "c4u-web-components";
import { FormikHelpers } from "formik";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import { InsuranceHelper } from "../../../../helper";
import {
  useInsurance,
  useInsuranceContext,
  useSessionContext,
} from "../../../../hooks";
import {
  IFormPaymentYelum,
  IProposalDetail,
  IQuoteModelPlan,
  OriginEnum,
  ParamsModel,
  PaymentRequest,
} from "../../../../models";
import {
  FeedbackModal,
  FormPaymentYelumMolecule,
  YelumCcIframeMolecule,
} from "../../../molecules";
import { WrapperYelum } from "./payment-yelum.organism.style";

enum YelumPaymentPageEnum {
  CcIframe = 1,
  ChoosePlots,
}

interface ISelectedPlot {
  numberPlots: number;
  paymentFormCode: number;
}

interface IProps {
  title?: string;
  selectedPlanData: IQuoteModelPlan;
  paymentLink: string;
  origin: OriginEnum;
  proposalDetail?: IProposalDetail;
}

export const PaymentYelumOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleFormikException, handleEventGTM } = useSessionContext();
  const { getSelectedPlanContext, quotesContext } = useInsuranceContext();

  const { savePayment } = useInsurance();

  const { id: customerId, typeJourney } = useParams<ParamsModel>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmError, setConfirmError] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState<ISelectedPlot>();

  const [yelumPaymentPage, setYelumPaymentPage] = useState<number>(
    YelumPaymentPageEnum.ChoosePlots
  );

  const handleGtmPurchaseYelum = useCallback(
    (planId: string) => {
      const selectedPlanId = quotesContext?.quotes?.find(
        (f) => !!f.plans.find((f) => f.planId === planId)
      );
      if (!selectedPlanId) return;

      const selectedQuotesModule = { ...selectedPlanId };

      const selectedIndex = selectedQuotesModule?.plans.findIndex(
        (plan) => plan.planId === planId
      );
      selectedQuotesModule.plans = selectedQuotesModule?.plans.filter(
        (f) => f.planId === planId
      );

      handleEventGTM({
        event: "purchase",
        ecommerce: {
          transaction_id: `${customerId}`,
          value: `${selectedQuotesModule.plans.map(
            (quote) => quote.annualPrice
          )}`,
          currency: "BRL",
          items: {
            item_name:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_id: selectedQuotesModule.plans.map((quote) => quote.planId),
            price: selectedQuotesModule.plans.map((quote) => quote.annualPrice),
            currency: "BRL",
            affiliation:
              selectedQuotesModule.companyName === t("ErrorApi")
                ? t("notAvailable")
                : selectedQuotesModule.companyName,
            item_category: "Seguros Auto",
            item_list_name: selectedQuotesModule.companyName,
            index: selectedIndex,
            quantity: 1,
          },
        },
      });
    },
    [customerId, handleEventGTM, quotesContext?.quotes, t]
  );

  const onSubmitPlots = useCallback(
    async (
      values: IFormPaymentYelum,
      formikHelpers: FormikHelpers<IFormPaymentYelum>
    ) => {
      try {
        const [numberPlots, paymentFormCode] =
          InsuranceHelper.splitSelectedPlotValue(values.plots);
        setSelectedPlot({ numberPlots, paymentFormCode });
        setYelumPaymentPage(YelumPaymentPageEnum.CcIframe);
      } catch (e) {
        handleFormikException(e, formikHelpers.setErrors);
      }
    },
    [handleFormikException]
  );

  const eventPurchase = useCallback(() => {
    const planData = getSelectedPlanContext();
    const plan = {
      id: planData?.planId,
    };

    if (plan.id) handleGtmPurchaseYelum(plan.id);
  }, [getSelectedPlanContext, handleGtmPurchaseYelum]);

  const onSubmitPayment = useCallback(async () => {
    try {
      const { numberPlots, paymentFormCode } = selectedPlot ?? {
        numberPlots: 0,
        paymentFormCode: 0,
      };
      const request = new PaymentRequest({
        customerId: customerId,
        numberInstallments: numberPlots,
        paymentFormCode: paymentFormCode,
        proposalDetail: InsuranceHelper.getProposalDetail(
          props.selectedPlanData,
          numberPlots,
          paymentFormCode,
          t
        ),
      });
      await savePayment(request);
      eventPurchase();
      setConfirmModal(true);
    } catch (e) {
      setConfirmError(true);
      console.log("Error saving payment", e);
    }
  }, [
    selectedPlot,
    customerId,
    props.selectedPlanData,
    t,
    savePayment,
    eventPurchase,
  ]);

  const onCloseSuccessModal = useCallback(
    () => history.push(paths.myQuotes(typeJourney)),
    [history, typeJourney]
  );

  const onCloseErrorModal = useCallback(
    () => history.push(paths.myQuotes(typeJourney)),
    [history, typeJourney]
  );

  return (
    <WrapperYelum>
      {customerId !== undefined && (
        <Row className="my-5">
          {yelumPaymentPage === YelumPaymentPageEnum.ChoosePlots ? (
            <Col>
              <FormPaymentYelumMolecule
                selectedPlanData={props.selectedPlanData}
                onSubmit={onSubmitPlots}
              />
            </Col>
          ) : (
            <Col>
              <YelumCcIframeMolecule
                paymentLink={props.paymentLink}
                onSubmitCC={onSubmitPayment}
              />
            </Col>
          )}
        </Row>
      )}

      <FeedbackModal
        type={"success"}
        show={confirmModal}
        onHide={onCloseSuccessModal}
        title={t("Successfully submitted form")}
        titleHeader={t("Insurance send confirmation")}
        personalButton={
          <ButtonTertiary sizex={"md"} onClick={onCloseSuccessModal}>
            {t("My quotes")}
          </ButtonTertiary>
        }
        content={
          <>
            {props.origin === OriginEnum.Dt && (
              <>
                {t("Please wait for the financing payment")}
                <br />
              </>
            )}
            {t("Insurance term is one year")}
          </>
        }
        size="md"
      />
      <FeedbackModal
        type={"error"}
        show={confirmError}
        onHide={onCloseErrorModal}
        personalButton={
          <ButtonTertiary sizex={"md"} onClick={onCloseErrorModal}>
            {t("My quotes")}
          </ButtonTertiary>
        }
        title={t("A problem found")}
        content={t("GenericErrorMsg")}
      />
    </WrapperYelum>
  );
};
