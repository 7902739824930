import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { FormikControlTranslatedAtom } from "../../../../atoms";
import { InsuranceHelper } from "../../../../../helper";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, MsgValidation } from "c4u-web-components";
import {
  IFormPaymentYelum,
  IQuoteModelPlan,
  PaymentFormCodeEnum,
} from "../../../../../models";

interface IProps {
  selectedPlanData: IQuoteModelPlan;
  onSubmit: (
    values: IFormPaymentYelum,
    formikHelpers: FormikHelpers<IFormPaymentYelum>
  ) => void;
}

export const FormPaymentYelumMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const dataDropdownPlots = useMemo(() => {
    return InsuranceHelper.getDataDropdownPlots(
      PaymentFormCodeEnum.CreditCard,
      props.selectedPlanData,
      t
    );
  }, [props, t]);

  const msgRequired = t(MsgValidation.RequiredField);
  const validations = Yup.object<IFormPaymentYelum>({
    plots: Yup.string().required(msgRequired),
  });

  const formik = useFormik<IFormPaymentYelum>({
    initialValues: {} as IFormPaymentYelum,
    onSubmit: props.onSubmit,
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (dataDropdownPlots?.length)
      formik.setFieldValue("plots", dataDropdownPlots[0].value);
    // eslint-disable-next-line
  }, [dataDropdownPlots]);

  return (
    <Form
      onSubmit={(e: any) => formik.handleSubmit(e)}
      className={"form-default"}
    >
      <Form.Row>
        <FormikControlTranslatedAtom
          sm={6}
          md={4}
          as={"select"}
          formik={formik}
          property={"plots"}
          label={t("Plots amount")}
        >
          {dataDropdownPlots.map((m, i) => (
            <option value={m.value} key={i}>
              {m.title}
            </option>
          ))}
        </FormikControlTranslatedAtom>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md className={"text-right pt-4"}>
          <ButtonPrimary
            type={"submit"}
            sizex={"md"}
            loading={formik.isSubmitting}
          >
            {t("Continue")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
